@font-face {
  font-family: Circular Std Book;
  src: url("./fonts/CircularStd-Book.eot");
  src: url("./fonts/CircularStd-Book.eot?#iefix") format("embedded-opentype"),
    url("./fonts/CircularStd-Book.woff2") format("woff2"),
    url("./fonts/CircularStd-Book.woff") format("woff"),
    url("./fonts/CircularStd-Book.ttf") format("truetype"),
    url("./fonts/CircularStd-Book.svg#CircularStd-Book") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

$lightblue: #c5e5ff;
$blue: #12328a;
$darkblue: #012179;

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

table {
  width: 100%;
}

label {
  display: block;
  font: normal normal normal 18px/54px Circular Std Book;
}

h2 {
  font-family: Circular Std Book;
}

.field.radio label:not(:first-child) {
  display: inline-block;
}

.field.radio input {
  width: 10%;
}

.fieldTable label {
  width: 100%;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
}

.steps {
  text-align: center;
}
.step {
  display: inline-block;
  text-align: center;
  width: 200px;
  vertical-align: middle;
}
.stepNumber {
  border-radius: 50%;
  width: 57px;
  height: 57px;
  margin: 0 auto;

  opacity: 1;
  color: $blue;
  text-align: center;

  font: normal normal normal 16px/54px Circular Std Book;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddfe1;
}

.current .stepNumber {
  color: white;
  border: none;
  background: transparent linear-gradient(90deg, #6d87b8 0%, #0f328c 100%) 0% 0%
    no-repeat padding-box;
}

.stepline {
  background: #e9e9e9 0% 0% no-repeat padding-box;
  //background: #f9f9f9 0% 0% no-repeat padding-box;
  opacity: 0.5;
  width: 10%;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: #{"max(-1vw, -17px)"};
  margin: 0px -77px;
  z-index: -1;
}

.current.stepline {
  background: transparent
    linear-gradient(270deg, #0f318b 0%, #13358d 54%, #95b2dd 100%) 0% 0%
    no-repeat padding-box;
  opacity: 0.5;
}

.stepName {
  font: normal normal normal #{"min(1.5vw, 32px)"} Circular Std Book;
  text-transform: capitalize;
}

.required {
  color: red;
  font-size: 125%;
}

.errors {
  border: 2px solid red;
  width: 300px;
  margin: 0 auto;
  padding: 10px;
}

.invalid {
  border: 1px solid red;
}

.field {
  display: inline-block;
  min-width: 33%;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}

.fieldTable td {
  padding: 5px;
}

.buttons {
  width: 100%;
  text-align: right;
  margin-bottom: 60px;
  margin-right: 28px;
}

.button {
  width: 135px;
  height: 35px;
  border: none;
  padding: 0 25px;
  font: normal normal normal 18px/35px Circular Std Book;
  position: relative;
  display: inline-block;

  svg {
    position: absolute;
    top: 10px;
    width: 15px;
    height: 15px;
  }
}

.button.previous {
  background-color: #f3f3f3;
  margin-right: 20px;
  color: #6a707e;
  svg {
    left: 5px;
  }
}

.button.next {
  background-color: $blue;
  color: white;

  svg {
    right: 5px;
  }
}

input:not(.button),
select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: white;
  border: 1px solid gray;
}

.radiobuttonlabel {
  width: auto;
}

.form {
  padding: 20px;
}

/*TODO move user-specific CSS from here to its own files and load them depending on whose loan application it is */
#step-2-row-0-field-0 input {
  width: 204px;
}

#Header {
  background-color: $blue;
  color: white;
}
#Header a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin: 0 10px;
}

.StepHeaderLabel {
  font-size: x-large;
}

.sidebar {
  height: 100%;
  width: 390px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  color: white;
  background-color: $blue;
  overflow-x: hidden;
  padding: 47px;
  box-sizing: border-box;
}

.sidebar h1 {
  text-align: center;
  font: normal normal bold 39px/54px Century Gothic Pro;
  font-family: century-gothic-pro, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 96px;
}

.sidebarsteps {
  text-align: left;
  font: normal normal normal 16px/54px Circular Std Book;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin: 0 auto;
  width: fit-content;
}

.sidebarsteps .sidebarStepNumber {
  background: none;
  border: none;
  color: white;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: 0px 3px;
  padding: 2px;
}

.sidebarsteps .sidebarStepNumber.current {
  border: 1px solid white;
  border-radius: 50%;
  text-align: center;
}

.sidebarsteps .divider {
  display: inline-block;
}

.sidebarsteplogo {
  text-align: center;
  margin-top: 86px;
  margin-bottom: 20px;
}

.sidebarsteplogo img {
  width: 60px;
  height: 60px;
}

.sidebarstepname {
  text-align: center;
  text-transform: uppercase;
  font: normal normal normal 18px/54px Circular Std Book;
}

.main {
  margin-left: 390px;
}

hr {
  visibility: hidden;
}

.react-datepicker-wrapper {
  width: 100%;
}

.field svg {
  position: absolute;
  right: 10px;
  padding: 10px;
  color: gray;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

@media only screen and (max-width: 810px) {
  label {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  .sidebar {
    height: 75px;
    width: 100%;
    display: flex;
    padding: 20px 6.7vw;
  }

  .sidebar h1 {
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
  }

  .sidebar h1 img {
    width: 29.2vw;
  }

  .sidebarsteps {
    display: none;
  }

  .sidebarstepname {
    margin-left: 18vw;
    font-size: 12px;
    line-height: 12px;
    align-self: center;
  }
  .sidebarsteplogo {
    margin: 0;
    order: 3;
    margin-left: 8px;
    position: absolute;
    right: 35px;
  }

  .sidebarsteplogo {
    align-self: center;
  }
  .sidebarsteplogo img {
    width: 40px;
    height: 40px;
  }

  .main {
    margin-left: 0;
    margin-top: 75px;
    padding: 0 7vw;
    overflow: hidden;
  }

  .stepline {
    display: none;
  }

  .step {
    width: 20%;
  }

  .stepName {
    font-size: 10px;
  }

  .stepNumber {
    width: 10.5vw;
    height: 10.5vw;
    line-height: 10.5vw;
  }

  .form {
    padding: 0;
  }
  .field:not(.fieldTable) {
    width: 50%;
  }

  .fieldTable input:not(.button),
  select {
    width: 100% !important;
  }

  table {
    width: 100%;
    table-layout: fixed;
  }
}

#LoginBox {
  color: white;
  font-family: "PT Sans", sans-serif;
  background-color: $blue;
  width: 50%;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  text-align: center;
}

#LoginBox input[type="text"],
#LoginBox input[type="password"] {
  width: 100%;
  margin-top: 3px;
  background-color: white;
}

#LoginBox input[type="submit"],
#LoginBox button {
  background-color: $darkblue;
  border: none;
  color: white;
  margin-top: 2px;
  display: inline-block;
  width: 45%;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 5px;
}

#LoginBox a {
  clear: both;
  float: left;
  color: white;
  text-decoration: none;
}

.applicationsTable .tableHeader {
  background-color: $blue;
  color: #ffffff;
  border: 1px solid $blue;
  font-size: 8pt;
  padding: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.applicationsTable .tableHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  text-align: center;
  border-right: 1px solid black;
}

.applicationsTable .tableCell {
  color: #3d3d3d;
  padding: 4px;
  vertical-align: top;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.applicationsTable {
  border-collapse: collapse;
}
.applicationsTable th,
.applicationsTable td {
  margin: 0px;
}

.backButton,
.submitButton {
  display: inline-block;
  max-width: 50%;
  color: white;
  margin: 10px;
}

.backButton {
  background-color: #a21817;
}

.submitButton {
  background-color: $darkblue;
}

fieldset {
  margin-bottom: 10px;
  background-color: #eeeeee;
}
legend {
  background-color: $darkblue;
  color: white;
  padding: 5px 10px;
  font-size: 1.2em;
}
